import React from 'react';
import styled from 'src/style';
import { PerksContainer } from './elements';
import Check from '../Icons/Check';
import colors from 'src/style/colors';
import { SubtitleDescription, sentenceStyler } from 'src/components/Typography';
import { H2, H3 } from 'src/components/Typography';

const Container = styled.div(sentenceStyler);

const Perks = React.memo(({ certification, bootcampType, format }) => {
  let perksListRNCP6 = [
    'Cours donnés en français',
    '1 formateur pour 10 personnes',
    'Interface de cours en ligne',
    'Plus de 100 heures de vidéos bonus',
    'Formation certifiante',
    'RNCP de niveau 6 (Bac+3/4)',
  ];
  let perksListRNCP5 = [
    'Cours donnés en français',
    `Des formateurs disponibles en RDV\u00a0individuels`,
    'Interface de cours en ligne',
    'RNCP de niveau 5 (Bac+2)',
  ];

  const perksListOnSite = [
    'Cours dans nos locaux à Paris',
    'Un écran en plus par étudiant',
    'Cuisine et espace détente',
  ];

  const perksListOnRemote = ['Cours et assistance via Zoom'];

  const perksListAlternance = ["Formation financée par l'entreprise", 'Embauche en CDI ou CDD'];

  let perksList = [];
  // certification
  if (certification === 'RNCP6') {
    perksList = perksListRNCP6;
  } else if (certification === 'RNCP5') {
    perksList = perksListRNCP5;
  }
  // bootcampType
  if (bootcampType === 'Alternance') {
    perksList = perksListRNCP6.concat(perksListAlternance);
  } else if (bootcampType === 'Temps Plein' || bootcampType === 'Temps Partiel') {
    perksList = perksListRNCP6.concat([
      'Éligible CPF et Aides Régions',
      'Éligible Transitions Pro',
      'Convention de stage de 6 mois',
    ]);
  }

  if (format === 'onSite') perksList = perksList.concat(perksListOnSite);

  if (format === 'onRemote') perksList = perksList.concat(perksListOnRemote);

  return (
    <PerksContainer>
      <H2>
        {perksList.length ? (
          <SubtitleDescription css={{ color: colors.purple }}>
            Les + du Reacteur :
          </SubtitleDescription>
        ) : null}
      </H2>

      {perksList.map((item) => (
        <div key={item} css={{ display: 'flex', paddingTop: 20 }}>
          <Check color={colors.purple} css={{ width: 16 }} />
          <Container css={{ fontSize: 17 }}>
            <H3>{item}</H3>
          </Container>
        </div>
      ))}
    </PerksContainer>
  );
});

export default Perks;
