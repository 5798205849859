import React, { useEffect } from 'react';

const ConditionalFormPart = ({ children, isVisible, onVisibleChange = () => {} }) => {
  useEffect(() => {
    onVisibleChange((prev) => (prev === isVisible ? null : isVisible));
  }, [isVisible]);
  if (!isVisible) return null;
  return <div>{children}</div>;
};

export default ConditionalFormPart;
