import React, { useContext, useEffect } from 'react';
import { LeReacteurContext } from '../../Layout';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormContainer } from '../elements';
import { DateInput, BackButton, ErrorMessage } from '../../Form';
import { InputForm, Label, NextBtn, InputPhone } from '../../Form/elements';
import { flags } from '../../Icons/Flags';
import { mediaQueries } from 'src/style/breakpoints';
import { useRestoreFormManager } from 'src/utils/RestoreForm';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

const SecondBlockSchema = Yup.object().shape({
  firstName: Yup.string().required('Champ obligatoire'),
  lastName: Yup.string().required('Champ obligatoire'),
  birthDate: Yup.string().typeError('Date non valide').required('Champ obligatoire'),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'Email non valide')
    .required('Champ obligatoire'),
  phoneNumber: Yup.string()
    .required('Champ obligatoire')
    .test({
      test: (num) => {
        if (num) {
          if (num.indexOf('+330') !== -1) {
            num = num.replace('+330', '+33');
            return isPossiblePhoneNumber(num);
          } else {
            return isPossiblePhoneNumber(num);
          }
        }
      },
      message: 'Numéro de téléphone non valide',
    }),
  // .required('Champ obligatoire'),
});

const FORM_TOKEN = 'apply-step2';

const SecondBlock = ({ onSubmit, onBackClicked, error, GRCtoken }) => {
  const { dtlVirtualPageView } = useContext(LeReacteurContext);
  const manager = useRestoreFormManager();
  const initialValues = {
    firstName: '',
    lastName: '',
    birthDate: '',
    email: '',
    phoneNumber: '',
    ...(manager.get(FORM_TOKEN) || {}),
  };

  useEffect(() => {
    if (window) {
      window.scrollTo({
        top: 90,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      return null;
    }
  });

  useEffect(() => {
    dtlVirtualPageView('bootcamp', '2', 'Coordonnées');
  }, []);

  return (
    <FormContainer>
      <Formik
        initialValues={{
          ...initialValues,
        }}
        validationSchema={SecondBlockSchema}
        validateOnMount={true}
        // validate={(values) => {
        //   manager.set(FORM_TOKEN, values);
        // }}
        onSubmit={(values) => {
          manager.set(FORM_TOKEN, values);
          const questions = {
            firstName: values.firstName,
            lastName: values.lastName,
            birthDate: values.birthDate,
            email: values.email,
            phoneNumber: values.phoneNumber,
          };
          onSubmit(questions);
        }}
      >
        {({ errors, handleChange, setFieldValue, touched, values, isSubmitting }) => {
          const isDisabled = () => {
            try {
              SecondBlockSchema.validateSync(values);
              return false;
            } catch (error) {
              return true;
            }
          };
          return (
            <Form css={{ maxWidth: 585, width: '100%' }}>
              <div>
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    [mediaQueries.tabletPortraitUp]: {
                      flexDirection: 'row',
                    },
                  }}
                >
                  <div>
                    <Label>Prénom</Label>
                    <InputForm
                      errorStyle={errors.firstName && touched.firstName ? true : false}
                      type="firstName"
                      name="firstName"
                      onChange={handleChange}
                      value={values.firstName}
                    />
                    <ErrorMessage
                      isVisible={errors.firstName && touched.firstName}
                      message={errors.firstName}
                    />
                  </div>
                  <div
                    css={{
                      marginTop: 40,
                      [mediaQueries.tabletPortraitUp]: {
                        marginTop: 0,
                        marginLeft: 25,
                      },
                    }}
                  >
                    <Label>Nom</Label>
                    <InputForm
                      type="lastName"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      errorStyle={errors.lastName && touched.lastName ? true : false}
                    />
                    <ErrorMessage
                      isVisible={errors.lastName && touched.lastName}
                      message={errors.lastName}
                    />
                  </div>
                </div>
                <Label
                  css={{
                    marginTop: 40,
                    [mediaQueries.tabletPortraitUp]: {
                      marginTop: 50,
                    },
                  }}
                >
                  Date de naissance
                </Label>
                <DateInput
                  value={values.birthDate}
                  errors={errors.birthDate && touched.birthDate}
                  onChange={(date) => {
                    setFieldValue('birthDate', date);
                  }}
                />
                <ErrorMessage
                  isVisible={errors.birthDate && touched.birthDate}
                  message={errors.birthDate}
                />
                <div
                  css={{
                    display: 'flex',
                    marginTop: 40,
                    flexDirection: 'column',
                    [mediaQueries.tabletPortraitUp]: {
                      flexDirection: 'row',
                      marginTop: 50,
                    },
                  }}
                >
                  <div>
                    <Label>Email</Label>
                    <InputForm
                      name="email"
                      type="text"
                      value={values.email}
                      onChange={handleChange}
                      errorStyle={errors.email && touched.email ? true : false}
                    />
                    <ErrorMessage
                      isVisible={errors.email && touched.email}
                      message={errors.email}
                    />
                  </div>
                  <div
                    css={{
                      marginTop: 40,
                      [mediaQueries.tabletPortraitUp]: {
                        marginTop: 0,
                        marginLeft: 25,
                      },
                    }}
                  >
                    <Label>Téléphone</Label>
                    <InputPhone
                      errors={errors}
                      touched={touched}
                      placeholder="Entrez votre numéro"
                      autoComplete="tel"
                      country="FR"
                      defaultCountry="FR"
                      flags={flags}
                      displayInitialValueAsLocalNumber={true}
                      value={values.phoneNumber}
                      onChange={(number) => {
                        setFieldValue('phoneNumber', number);
                      }}
                    />
                    <ErrorMessage
                      isVisible={errors.phoneNumber && touched.phoneNumber}
                      message={errors.phoneNumber}
                    />
                  </div>
                </div>
              </div>

              <div
                css={{
                  display: 'flex',
                  alignItems: 'baseline',
                  justifyContent: 'space-between',
                  [mediaQueries.tabletPortraitUp]: {
                    maxWidth: 465,
                  },
                  [mediaQueries.tabletLandscapeUp]: {
                    maxWidth: 585,
                  },
                }}
              >
                {/* <BackButton onClick={onBackClicked} /> */}
                <BackButton
                  onClick={() => {
                    manager.set(FORM_TOKEN, values);
                    onBackClicked();
                  }}
                />
                <NextBtn
                  data-cy="suivant2"
                  type="submit"
                  disabledStyle={isDisabled()}
                  disabled={isSubmitting}
                >
                  Suivant
                </NextBtn>
              </div>
              {error && (
                <ErrorMessage
                  css={{ textAlign: 'end' }}
                  isVisible={error}
                  message={
                    GRCtoken
                      ? 'Une erreur est survenue.'
                      : "Une erreur est survenue ! Vérifiez que vous n'êtes pas derrière un VPN. Si le problème persiste, merci de nous contacter directement."
                  }
                />
              )}
            </Form>
          );
        }}
      </Formik>
      {/* <PerksWrapper>
        <Perks />
      </PerksWrapper> */}
    </FormContainer>
  );
};
export default SecondBlock;
