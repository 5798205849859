import React, { useContext, useEffect } from 'react';
import { LeReacteurContext } from '../../Layout';
import { SuccessBlockContainer } from '../elements';
import { NextBtn } from '../../Form/elements';
import { Paragraph } from 'src/components/Typography';
import { navigate } from 'gatsby';

const SuccessBlock = ({ name, fromFormationsPage, onFormSuccess, stepsData }) => {
  const { dtlFormValidation } = useContext(LeReacteurContext);
  useEffect(() => {
    if (window) {
      window.scrollTo({
        top: 90,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      return null;
    }
  });

  useEffect(() => {
    dtlFormValidation('bootcamp');
  }, []);

  return (
    <SuccessBlockContainer>
      <Paragraph css={{ fontWeight: 'bold', padding: 0 }}>
        Merci pour votre intérêt, {name} !
      </Paragraph>
      <br />
      <Paragraph css={{ maxWidth: 531, paddingTop: 0, textAlign: 'center' }}>
        Vous allez recevoir un mail de confirmation (vérifiez vos SPAMs ou vos communications
        commerciales si vous utilisez&nbsp;Gmail){' '}
        {stepsData.step1.certification === 'RNCP6' ? ' avec un test de logique à réaliser' : ''}.
        <br></br>Sandrine qui s'occupe des inscriptions va vous rappeler au&nbsp;plus&nbsp;vite
        {stepsData.step1.certification === 'RNCP6' ? ', une fois le test passé' : ''} !{' '}
        <span role="img" aria-label="emoji smile">
          🙂
        </span>
      </Paragraph>

      <NextBtn
        onClick={() => {
          navigate(
            fromFormationsPage && fromFormationsPage.type === 'Temps Plein'
              ? `/bootcamp-javascript/formation-developpeur-web-mobile/`
              : fromFormationsPage && fromFormationsPage.type === 'Temps Partiel'
              ? `/bootcamp-javascript/formation-developpeur-web-mobile-temps-partiel/`
              : '/'
          );
          onFormSuccess();
        }}
      >
        Fermer
      </NextBtn>
    </SuccessBlockContainer>
  );
};
export default SuccessBlock;
