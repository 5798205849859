import React, { useState } from 'react';
import { Section } from 'src/components/Section';
import Layout from 'src/components/Layout';
import { TopbarStaticSpace } from 'src/components/Topbar';
import { FormHeader } from 'src/components/Form';
import { mediaQueries } from 'src/style/breakpoints';
import sizes from 'src/style/sizes';
import { FormProgressionAndBlock } from 'src/components/ApplicationForm';
import { QuestionCTA } from 'src/components/Bootcamp';

const ApplyFormPage = ({ location }) => {
  const [step, setStep] = useState(1);

  const fromFormationsPage = location && location.state ? location.state.fromFormationsPage : null;
  return (
    <Layout
      pathname={location.pathname}
      businessType="B2C"
      pageName="Postuler bootcamp"
      pageType="form"
      formStart={true}
      formType="bootcamp"
      metaTitle="Postulez à l'une de nos sessions de formation | Le Reacteur"
      metaDescription="Vous souhaitez apprendre à coder ? Rejoignez les rangs du Reacteur et devenez rapidement Développeur Web et Mobile."
    >
      <Section
        theme="primary"
        css={{
          [mediaQueries.phoneOnly]: {
            height: sizes.purpleBgHeightMobile,
          },
          [mediaQueries.tabletPortraitUp]: {
            height: sizes.purpleBgHeightTablet,
          },
          [mediaQueries.desktopUp]: {
            height: sizes.purpleBgHeightDesktop,
          },
        }}
      />
      <TopbarStaticSpace />
      <Section theme="secondary">
        <div
          css={{
            [mediaQueries.phoneOnly]: {
              marginTop: -160,
            },
            [mediaQueries.tabletPortraitUp]: {
              marginTop: -370,
            },
            [mediaQueries.tabletLandscapeUp]: {
              marginTop: -330,
            },
            [mediaQueries.desktopUp]: {
              marginTop: sizes.marginTopContainer,
            },
          }}
        >
          <FormHeader
            mainTitle="Postuler"
            // subTitle="Formation Développeur Full Stack Web et Mobile"
          />
          <FormProgressionAndBlock
            step={step}
            setStep={setStep}
            fromFormationsPage={fromFormationsPage}
          />
        </div>
      </Section>
      <QuestionCTA />
    </Layout>
  );
};

export default ApplyFormPage;
