import React from 'react';
// import { useRef } from 'react';
import FormBlock from './FormBlock';
import { useRestoreFormManager } from 'src/utils/RestoreForm';
import { FormStepsContainer } from './elements';
import { ArrowColored } from '../Icons/arrows';
import { mediaQueries } from 'src/style/breakpoints';
import colors from 'src/style/colors';
// import * as Yup from 'yup';
// import { isPossiblePhoneNumber } from 'react-phone-number-input';

const FORM_TOKEN = 'apply-form';

const FormProgressionAndBlock = ({ step, setStep, fromFormationsPage }) => {
  const manager = useRestoreFormManager();

  const [stepsData, setStepsData] = React.useState(() => {
    const restored = manager.get(FORM_TOKEN);

    if (restored) {
      return restored;
    }
    return {
      step1: null,
      step2: null,
      step3: null,
    };
  });

  React.useEffect(() => {
    manager.set(FORM_TOKEN, stepsData);
  }, [manager, stepsData]);

  return (
    <>
      <FormStepsContainer data-cy="fromStepsContainer">
        <p
          css={{
            textAlign: 'left',
            color: step >= 1 ? colors.purple : '#DADADA',
            [mediaQueries.tabletPortraitUp]: {
              flexWrap: 'wrap',
              padding: '0 10px 0 0',
              flex: 1,
            },
            [mediaQueries.tabletLandscapeUp]: {
              padding: 0,
            },
          }}
        >
          Choix de la session
        </p>
        <ArrowColored color={step >= 1 ? colors.purple : '#DADADA'} />
        <p
          css={{
            textAlign: 'center',
            color: step >= 2 ? colors.purple : '#DADADA',
            [mediaQueries.tabletPortraitUp]: {
              flexWrap: 'wrap',
              padding: '0 10px 0 0',
              flex: 1,
            },
            [mediaQueries.tabletLandscapeUp]: {
              padding: 0,
            },
          }}
        >
          Coordonnées
        </p>
        <ArrowColored color={step >= 2 ? colors.purple : '#DADADA'} />
        <p
          css={{
            textAlign: 'center',
            color: step >= 3 ? colors.purple : '#DADADA',
            [mediaQueries.tabletPortraitUp]: {
              flexWrap: 'wrap',
              padding: '0 10px 0 0',
              flex: 1,
            },
            [mediaQueries.tabletLandscapeUp]: {
              padding: 0,
            },
          }}
        >
          Finalisation
        </p>
      </FormStepsContainer>
      <FormBlock
        step={step}
        setStep={setStep}
        fromFormationsPage={fromFormationsPage}
        setStepsData={setStepsData}
        stepsData={stepsData}
        onFormSuccess={() => {
          manager.reset('apply-form');
          manager.reset('apply-step1');
          manager.reset('apply-step2');
          manager.reset('apply-step3');
        }}
      />
    </>
  );
};

export default FormProgressionAndBlock;
