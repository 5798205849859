import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FormBlockSection } from 'src/components/Form/elements';
import FirstBlock from './blocks/FirstBlock';
import SecondBlock from './blocks/SecondBlock';
import ThirdBlock from './blocks/ThirdBlock';
import SuccessBlock from './blocks/SuccessBlock';
import Cookies from 'js-cookie';
import { isAfter } from 'date-fns';
import { GRC } from 'src/components/Form';

const action = 'Postuler';

const FormBlock = ({
  step,
  setStep,
  fromFormationsPage,
  setStepsData,
  stepsData,
  onFormSuccess,
}) => {
  const [sessions, setSessions] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [GRCtoken, setGRCToken] = React.useState(null);

  useEffect(() => {
    axios.get(`${process.env.GATSBY_BASE_URL_API}/session/public`).then((response) => {
      setSessions(response.data);
    });
  }, []);

  const fullTimeSessions = React.useMemo(() => {
    return sessions.filter(
      (session) =>
        session.format &&
        session.format === 'full-time' &&
        session.course.slug === 'formation-developpeur-web-mobile' &&
        !isAfter(new Date(), new Date(session.startAt))
    );
  }, [sessions]);

  const alternanceSessions = React.useMemo(() => {
    return sessions.filter(
      (session) =>
        session.format &&
        session.format === 'full-time' &&
        session.course.slug === 'formation-developpeur-web-mobile' &&
        !isAfter(new Date(), new Date(session.startAt))
    );
  }, [sessions]);

  const partTimeSessions = React.useMemo(() => {
    return sessions.filter(
      (session) =>
        session.format &&
        session.format === 'part-time' &&
        session.course.slug === 'formation-developpeur-web-mobile-temps-partiel' &&
        !isAfter(new Date(), new Date(session.startAt))
    );
  }, [sessions]);

  const eLearningSessions = React.useMemo(() => {
    return sessions.filter(
      (session) =>
        session?.format &&
        session.format === 'online' &&
        session?.course?.slug === 'formation-developpeur-web-et-web-mobile' &&
        !isAfter(new Date(), new Date(session.startAt))
    );
  }, [sessions]);

  const setFormData = React.useCallback(
    async (stepName, data) => {
      if (!GRCtoken) {
        setError(true);
      }
      const allStepsData = { ...stepsData };
      Object.assign(allStepsData, { [stepName]: data });
      setStepsData(allStepsData);

      const currentStepNumber = parseInt(stepName[4], 10);
      if (currentStepNumber === Object.keys(stepsData).length) {
        try {
          const utmSource = Cookies.get('tracking') || Cookies.get('utm_source') || '';
          const gclid = Cookies.get('_gcl_aw') || '';
          const fbclid = Cookies.get('fbclid') || '';
          const referrer = Cookies.get('referrer') || '';

          const analytics = {
            utmSource,
            gclid,
            fbclid,
            referrer,
          };

          const data = {
            ...allStepsData.step1,
            ...allStepsData.step2,
            ...allStepsData.step3,
            ...analytics,
          };

          const response = await axios.post(`${process.env.GATSBY_BASE_URL_NEW_API}/forms/apply`, {
            data,
            GRCtoken,
            action,
            source: document.location.href,
            track: {
              utmSource: Cookies.get('utm_source') || '',
              utmMedium: Cookies.get('utm_medium') || '',
              utmCampaign: Cookies.get('utm_campaign') || '',
              utmCampaignId: Cookies.get('utm_campaign_id') || '',
              adGroupName: Cookies.get('adgroup_name') || '',
              utmAdGroupId: Cookies.get('utm_adgroup_id') || '',
              utmTerm: Cookies.get('utm_term') || '',
              matchType: Cookies.get('matchtype') || '',
              utmContent: Cookies.get('utm_content') || '',
            },
          });

          if (response.data && response.data.status === 'COMPLETED_SUCCESSFULLY') {
            /* Event snippet for Candidature conversion page */
            //window.gtag_report_conversion();
            setError(false);
            setStep('SUCCESS');
            setIsLoading(false);
          }
        } catch (error) {
          console.error(error.message);
          setError(true);
          setIsLoading(false);
        }
      } else {
        setStep(currentStepNumber + 1);
        setError(false);
      }
    },
    [setStep, setStepsData, stepsData, GRCtoken]
  );
  const name = stepsData.step2 && stepsData.step2.firstName;

  // const sessionIdSelected =
  //   stepsData.step1 && stepsData.step1.session ? stepsData.step1.session.id : undefined;

  // const sessionSelected = sessionIdSelected
  //   ? sessions.find((item) => item.id === sessionIdSelected)
  //   : undefined;

  // let individualPrice = null;
  // let corporatePrice = null;
  // if (sessionSelected && sessionSelected.course && sessionSelected.prices) {
  //   individualPrice = sessionSelected.prices.map((item) => {
  //     if (stepsData.step1 && stepsData.step1.format === 'onSite') {
  //       if (item.name === 'particuliers') {
  //         const sessionPriceRounded = Math.round(item.price * (1 + item.vat / 100));
  //         return new Intl.NumberFormat('fr-FR').format(sessionPriceRounded) + '€ (particuliers)';
  //       }
  //     } else if (stepsData.step1 && stepsData.step1.format === 'onRemote') {
  //       if (item.name === 'telepresentiel') {
  //         const sessionPriceRounded = Math.round(item.price * (1 + item.vat / 100));
  //         return new Intl.NumberFormat('fr-FR').format(sessionPriceRounded) + '€ (particuliers)';
  //       }
  //     }
  //     return null;
  //   });
  //   corporatePrice = sessionSelected.prices.map((item) => {
  //     if (item.name === 'entreprises') {
  //       return new Intl.NumberFormat('fr-FR').format(item.price) + '€ HT (entreprises)';
  //     } else {
  //       return null;
  //     }
  //   });
  // }

  const renderBlock = (step) => {
    switch (step) {
      case 1:
        return (
          <GRC setGRCToken={setGRCToken} action={action + '_' + step}>
            <FirstBlock
              onSubmit={(step1Data) => {
                setFormData('step1', step1Data);
              }}
              fullTimeSessions={fullTimeSessions}
              partTimeSessions={partTimeSessions}
              alternanceSessions={alternanceSessions}
              eLearningSessions={eLearningSessions}
              fromFormationsPage={fromFormationsPage}
              sessions={sessions}
              error={error}
              GRCtoken={GRCtoken}
            />
          </GRC>
        );
      case 2:
        return (
          <GRC setGRCToken={setGRCToken} action={action + '_' + step}>
            <SecondBlock
              onSubmit={(data) => {
                setFormData('step2', data);
              }}
              onBackClicked={() => setStep(1)}
              setStep={setStep}
              error={error}
              GRCtoken={GRCtoken}
            />
          </GRC>
        );
      case 3:
        return (
          <GRC setGRCToken={setGRCToken} action={action}>
            <ThirdBlock
              onSubmit={(data) => {
                setFormData('step3', data);
                setIsLoading(true);
              }}
              onBackClicked={() => setStep(2)}
              setStep={setStep}
              error={error}
              GRCtoken={GRCtoken}
              isLoading={isLoading}
              stepsData={stepsData}
            />
          </GRC>
        );
      // Disable this part because these questions will be asked irl
      // case 4:
      //   return (
      //     <GRC setGRCToken={setGRCToken} action={action}>
      //       <FourthBlock
      //         onSubmit={(data) => {
      //           setFormData('step4', data);
      //           setIsLoading(true);
      //         }}
      //         onBackClicked={() => setStep(3)}
      //         individualPrice={individualPrice}
      //         corporatePrice={corporatePrice}
      //         error={error}
      //         isLoading={isLoading}
      //         GRCtoken={GRCtoken}
      //       />
      //     </GRC>
      //   );
      case 'SUCCESS':
        return (
          <SuccessBlock
            name={name}
            fromFormationsPage={fromFormationsPage}
            onFormSuccess={onFormSuccess}
            stepsData={stepsData}
          />
        );
      default:
        return null;
    }
  };
  return <FormBlockSection>{renderBlock(step)}</FormBlockSection>;
};

export default FormBlock;
